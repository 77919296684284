

































import { Component, Vue } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

@Component({
  metaInfo(): MetaInfo {
    return {
      title: 'Über mich als Coach',
      meta: [
        {
          name: 'description',
          content:
            'Meine Erfahrungen und mein Lebensweg helfen dabei, eine bessere Führungskraft zu werden.',
        },
      ],
    }
  },
})
export default class About extends Vue {}
