<template>
  <main class="about">
    <h1>Kontakt</h1>

    <div
      class="flex flex-col sm:flex-row justify-between space-y-32 sm:space-y-0 my-16"
    >
      <div class="grid grid-rows-2 space-y-2">
        <div class="space-x-12">
          <span class="font-700">Mobil</span>
          <a href="tel:+4369911609114">+43 699 116 09 114</a>
        </div>
        <div class="space-x-12">
          <span class="font-700">Email</span>
          <a href="mailto:info@stradner.coach">info@stradner.coach</a>
        </div>
      </div>
      <a
        href="https://form.jotform.com/211111932127341"
        class="flex justify-center items-center text-center hover:bg-fan-mint transition-colors shadow-2xl bg-fan-mint sm:bg-fan-mint90 text-white hover:text-white rounded-full text-xl tracking-wide font-700 h-64 px-24 py-12 sm:py-0 cursor-pointer"
      >
        Fragen zum Coaching? Schreib mir doch!
      </a>
    </div>
    <h1>Standort</h1>
    <p>
      Meine Praxis befindet sich "<b>Am Schwedenplatz</b>". Gut zu erreichen mit
      der U-Bahn (<b>U4</b> und <b>U1</b>) und Straßenbahnlinien (<b>1</b> und
      <b>2</b>): Station Schwedenplatz.
    </p>
  </main>
</template>

<script lang="js">
import { Component, Vue } from 'vue-property-decorator'
// import { MetaInfo } from 'vue-meta'

@Component({
  metaInfo() {
    return {
      title: 'Kontakt für junge Führungskräfte',
      meta: [
        {
          name: 'description',
          content:
            'Meine Kontaktdaten für ein rasches und unkompliziertes Kennenlernen.',
        },
      ],
    }
  },
})
export default class Contact extends Vue {}
</script>
